import Vue from 'vue'

export const PhrasesAvatarUpload = new Vue({
    data: {
        text: {
            avatar_renewed: 'Avatar renewed',
            back: 'Back',
            ok: 'OK',
        },
        errors: {
            wrong_format_or_resolution: 'Wrong file format or resolution',
            image_not_allowed: 'Image not allowed',
            incorrect_image: 'Incorrect image type or sizes',
            congratulations: 'Congratulations',
            successfully: 'Your image was successfully<br>uploaded to Camfrog',
        },
    },
});