<template>
  <div id="upload"
       :class="{ error: error.show }"
  >
    <div id="upload-file">
      <div id="upload-file-preview" :style="getImageStyle" />
      <input
        ref="input-file"
        type="file"
        accept="image/jpg, image/jpeg, image/png"
        @change="onChange($event.target)"
      >
      <div id="upload-file-content">
        <span>{{ errorTextInfo() }}</span>
        <div v-if="image_base64">
          <Button
            type="green"
            icon="folder"
            value=""
            :height="select_file_button.height"
            :width="select_file_button.height"
            @click="selectFile"
          />
          <Button
            type="green"
            value="Upload Image"
            :height="select_file_button.height"
            :width="select_file_button.width"
            @click="uploadFile"
          />
        </div>
        <div v-else>
          <Button
            type="green"
            icon="folder"
            value="Select Image"
            :height="select_file_button.height"
            :width="select_file_button.width"
            @click="selectFile"
          />
        </div>
      </div>
    </div>

    <div id="upload-error">
      <div id="upload-error-content"
           :class="error.type"
      >
        <div id="error-icon" />
        <h3 v-html="error.header" />
        <span v-html="error.text" />
      </div>
      <div id="button-action">
        <Button
          type="green"
          :value="error.button"
          :height="select_file_button.height"
          :width="select_file_button.width"
          @click="error.callback"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {PhrasesAvatarUpload} from '@/phrases/avatar_upload';

import Button from '@/components/Button';

export default {
  name: 'UploadFile',
  components: {
    Button,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image_base64: '',
      error: {
        show: false,
        type: '',
        header: '',
        text: '',
        button: '',
        callback: function () {
        },
      },
      select_file_button: {
        height: 50,
        width: 180,
      },

      phrases: PhrasesAvatarUpload,
    }
  },

  computed: {
    getImageStyle() {
      let image = this.image_base64 || this.options.image_current;

      if (!image) {
        return;
      }

      return {
        background:
          `no-repeat center/contain url(~@/assets/images/modal/upload_file_overlay.svg),
          no-repeat center/cover url(${image})`
      };
    },
  },

  methods: {
    errorTextInfo() {
      let formats = this.options.formats
        .map((format) => {
          format = format.toLowerCase();
          if (format === 'jpg' &&
            this.options.formats.includes('jpeg')) {
            return;
          }
          return format.toUpperCase();
        }).join(' or ');
      let resolutions = this.options.resolutions.width + 'x' + this.options.resolutions.height;
      return `Uploaded image must be ${formats},\r\n ${resolutions} pixels or lager.`;
    },
    selectFile() {
      this.$refs['input-file'].click();
    },
    onChange(input) {
      let file = input.files[0];
      let file_ext = file.name.split('.').pop().toLowerCase();

      if (!this.options.formats.includes(file_ext)) {
        input.value = '';
        return;
      }

      let reader = new FileReader();

      reader.onload = () => {
        let img = new Image();

        img.onload = () => {
          if (img.width < this.options.resolutions.width ||
            img.height < this.options.resolutions.height) {

            this.showError({
              type: 'warning',
              header: 'Wrong file format or resolution.',
              text: this.errorTextInfo(),
            });

            return;
          }

          this.image_base64 = reader.result;
        };

        img.src = reader.result;
      };

      reader.readAsDataURL(file);
    },
    uploadFile() {
      this.$ajax({
        type: 'avatar_upload',
        params: {
          type: 'avatar',
          base64: this.image_base64,
        },
        then: (data) => {
          if (data.image_id) {
            this.$store.commit('updateAvatarBase64', this.image_base64.toString());

            this.$store.dispatch('openToast', {
              type: 'success',
              text: this.$helper.getPhraseByKey(this.phrases.text, 'avatar_renewed'),
              delay: 5,
            });

            this.showError({
              type: 'success',
              header: this.$helper.getPhraseByKey(this.phrases.errors, 'congratulations'),
              text: this.$helper.getPhraseByKey(this.phrases.errors, 'successfully'),
            });
            return;
          }

          console.error('image_id not found on response');
        },
        catch: (data) => {
          let error = data[0] || {};

          this.showError({
            type: 'warning',
            header: this.$helper.getPhraseByKey(this.phrases.errors, error.code),
            // is there any reason to to show the same text twice? Need some explanation there.
            //text: this.$helper.getPhraseByKey(this.phrases.errors, error.code),
          });
        },
      });
    },
    showError(params) {
      let types = {
        error: this.$helper.getPhraseByKey(this.phrases.text, 'back'),
        warning: this.$helper.getPhraseByKey(this.phrases.text, 'ok'),
        success: this.$helper.getPhraseByKey(this.phrases.text, 'ok'),
      };

      if (types[params.type]) {
        this.error.type = params.type;
      } else {
        console.warn('Error type not found.');
        return;
      }
      this.error.header = params.header;
      this.error.text = params.text;
      this.error.button = types[params.type];
      this.error.callback = () => {
        this.error.show = false;
        if (params.type === 'success') {
          this.$store.commit('updateModalParams', {
            result: this.image_base64,
            opened: false,
          });
        }
      };
      this.error.show = true;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#upload {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-content: stretch;
  max-width: 380px;
  width: 100%;
  overflow: hidden;
}

#upload > div {
  min-width: 100%;
}
#upload-file {
  position: relative;
  width: 100%;
  margin-left: 0;
  transition: .4s;
}

#upload.error > #upload-file {
  margin-left: -100%;
}

#upload-file > input {
  display: none;
}

#upload-file-preview {
  position: relative;
  max-width: 380px;
  width: 100%;
  padding-bottom: 100%;
  background:
    no-repeat center url(~@/assets/images/modal/upload_file_folder.svg),
    no-repeat center/contain url(~@/assets/images/modal/upload_file_overlay.svg)
    #b1b1b1;
}

#upload-file-preview:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  padding-bottom: 100%;
}

#upload-file-preview > div {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

#upload-file-content {
  padding: 30px;
  box-sizing: border-box;
}

#upload-file-content > span {
  display: block;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #545454;
  line-height: 19px;
}

#upload-file-content > div > * {
  margin: 0 5px;
}

#upload-error {
  position: relative;
  width: 100%;
  font-size: 0;
  white-space: nowrap;
}

#upload-error:before {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}

#upload-error-content {
  display: inline-block;
  width: 100%;
  padding: 30px 30px 80px;
  box-sizing: border-box;
  white-space: normal;
  vertical-align: middle;
}

#error-icon {
  height: 80px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}

#upload-error-content.error > #error-icon {
  background-image: url(~@/assets/images/modal/icon_type_error.svg);
}

#upload-error-content.warning > #error-icon {
  background-image: url(~@/assets/images/modal/icon_type_warning.svg);
}

#upload-error-content.success > #error-icon {
  background-image: url(~@/assets/images/modal/icon_type_success.svg);
}

#upload-error-content > h3 {
  margin-top: 24px;
  font-weight: 400;
  font-size: 18px;
  color: #545454;
  line-height: 24px;
}

#upload-error-content > span {
  display: inline-block;
  width: 100%;
  margin-top: 12px;
  padding: 0 10px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: #545454;
  line-height: 20px;
}

#upload-error > #button-action {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
}
</style>
